import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import TableFeatures from 'components/Indoor_Cameras/IN-8015_HD/Product_Features/TableFeatures';
import NavButtons from 'components/Indoor_Cameras/IN-8015_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-8015 Full HD Manual Product Features",
  "path": "/Indoor_Cameras/IN-8015_HD/Product_Features/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "The IN-8015 HD is the first INSTAR camera with the new 1080p chipset.",
  "image": "./P_SearchThumb_IN-8015HD_Features.png",
  "social": "/images/Search/P_SearchThumb_IN-8015HD_Features.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-8015HD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='IN-8015 Full HD Manual Product Features' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-8015 HD is the first INSTAR camera with the new 1080p chipset.' image='/images/Search/P_SearchThumb_IN-8015HD_Features.png' twitter='/images/Search/P_SearchThumb_IN-8015HD_Features.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-8015_HD/Produkt_Features/' locationFR='/fr/Indoor_Cameras/IN-8015_HD/Product_Features/' crumbLabel="Features" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-8015-full-hd-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-8015-full-hd-manual",
        "aria-label": "in 8015 full hd manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-8015 Full HD Manual`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/79c694b1306de03ef2ef78865ba21fe9/573d3/IN-8015_Overview_width1650.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAABlElEQVQoz12QzY6bMBSFee9RRSNl2RfIKlkkQkoXWVRE6mI2Zeimmgn5MakQgnFGY+OEH8MQI9sKtxVISdqz8uJ+vt89htaXtgUhpO//llLa9nK5XJZlCf/mcrkAQNulfwCAIYQEANv+PhgMNpvNcDicTqe2bWute6yfwxhbljWbzSzLms/naZr2sNIagiCilE4mk9FolGUZY6woivtVhJD1ev3WBSHUqxn990VRLBYL0zQty8IYE0KSJLmH0zT1fZ8QmiSJjxDn/AYDwHg8Nk1zt9sxxo7HI2Ps6gwASqneiFIaRZGU8gbXde26ro8QpXS73QZB8B9cVSXpcsA4iqLz+XyDOeen0ykMw19dwjDM8/y+7f1+7z65z88vnrd2HCeO4xvseV7TNAAgpazrD4xxHMfXwpumKUt+FnXFTzXPtNJCCK2U0SlVSikA0FozxsIwdJyfCCGl1LWwVxx/+vzw4/Hb+OsXb//yUdVt2xr9wUEQIIQ8z1utVgihw+FdCHE9uGs7j2Oc5VVE3t4TWvLqr/Yfxv0eHLjHxn8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/79c694b1306de03ef2ef78865ba21fe9/e4706/IN-8015_Overview_width1650.avif 230w", "/en/static/79c694b1306de03ef2ef78865ba21fe9/d1af7/IN-8015_Overview_width1650.avif 460w", "/en/static/79c694b1306de03ef2ef78865ba21fe9/7f308/IN-8015_Overview_width1650.avif 920w", "/en/static/79c694b1306de03ef2ef78865ba21fe9/e1c99/IN-8015_Overview_width1650.avif 1380w", "/en/static/79c694b1306de03ef2ef78865ba21fe9/e02f7/IN-8015_Overview_width1650.avif 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/79c694b1306de03ef2ef78865ba21fe9/a0b58/IN-8015_Overview_width1650.webp 230w", "/en/static/79c694b1306de03ef2ef78865ba21fe9/bc10c/IN-8015_Overview_width1650.webp 460w", "/en/static/79c694b1306de03ef2ef78865ba21fe9/966d8/IN-8015_Overview_width1650.webp 920w", "/en/static/79c694b1306de03ef2ef78865ba21fe9/445df/IN-8015_Overview_width1650.webp 1380w", "/en/static/79c694b1306de03ef2ef78865ba21fe9/44758/IN-8015_Overview_width1650.webp 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/79c694b1306de03ef2ef78865ba21fe9/81c8e/IN-8015_Overview_width1650.png 230w", "/en/static/79c694b1306de03ef2ef78865ba21fe9/08a84/IN-8015_Overview_width1650.png 460w", "/en/static/79c694b1306de03ef2ef78865ba21fe9/c0255/IN-8015_Overview_width1650.png 920w", "/en/static/79c694b1306de03ef2ef78865ba21fe9/b1001/IN-8015_Overview_width1650.png 1380w", "/en/static/79c694b1306de03ef2ef78865ba21fe9/573d3/IN-8015_Overview_width1650.png 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/79c694b1306de03ef2ef78865ba21fe9/c0255/IN-8015_Overview_width1650.png",
              "alt": "IN-8015 Full HD Produkt Features",
              "title": "IN-8015 Full HD Produkt Features",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TableFeatures mdxType="TableFeatures" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-8015 Full HD is the successor of the IN-6012 HD camera solution for indoor surveillance and the first indoor camera with integrated passive-infrared motion detection. The camera head is remotely controllable and equipped with infrared diodes to utilize night vision in pitch dark environments. The integrated IR cut filter on the other hand allows for the capturing of native colours, as seen by the human eye. Through the use of an efficient h.264 compression codec it is able of recording video files on a SD memory card with comparably small size but best picture results. Any device (i.e. smartphones) can connect to the cameras web interface and allows you to control the pan & tilt feature. With 350 degrees horizontally and 100 degrees vertically the pan and tilt feature basically covers the whole area in front of the camera. Thanks to its compact size and its modern design, you won`}{`'`}{`t be just limited to use it as a surveillance camera but also for your personal projects. The FTP support allows you to use the IN-8015 HD as a multi-functional webcam for integration on your website.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      